<template>
  <async-container :loading="!loaded">
    <v-data-table
      :items="showInatives ? items : itemsActives"
      :headers="showInatives ? [{text: 'Situação', value: 'ativo', align: 'center'}, ...headers] : headers"
      :search="search"
      dense
      no-data-text="Nenhum colaborador encontrado"
      no-results-text="Nenhum colaborador encontrado"
      sort-by="nome"
      :footer-props="{'items-per-page-options': [10, 25, 50, -1]}"
      class="elevation-2"
    >
      <template v-slot:top>
        <v-card-title class="title-bar">
          Colaboradores
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="podeGerenciarUsuarios" @click="dialogNewUser = true">NOVO</v-btn>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{on: tooltip}">
              <v-btn color="primary" v-on="tooltip" @click="$snackbar({text: 'Sem permissão', color: 'error'})">NOVO</v-btn>
            </template>
            <span>Sem permissão</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            class="pt-0 mt-3 mb-2"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
            solo-inverted
            dense
          ></v-text-field>
          <v-switch
            v-if="podeGerenciarUsuarios"
            label="Exibir inativos"
            v-model="showInatives"
          ></v-switch>
        </v-card-text>
      </template>
      <template v-slot:item.avatar="{item}">
        <router-link :to="'/usuario/' + item.usuario">
          <v-avatar size="32">
            <img :src="item.avatar ? item.avatar : genericSrc" alt="">
          </v-avatar>
        </router-link>
      </template>
      <template v-slot:item.nome="{item}">
        <router-link :to="'/usuario/' + item.usuario" style="text-decoration: none">{{item.nome}}</router-link>
      </template>
      <template v-slot:item.ativo="{item}">
        <v-chip label small color="success" v-if="item.ativo">ATIVO</v-chip>
        <v-chip label small color="error" v-else>INATIVO</v-chip>
      </template>
      <template v-slot:item.cargo="{item}">{{item.cargo ? item.cargo.toUpperCase() : ''}}</template>
    </v-data-table>
    <v-dialog v-model="dialogNewUser" max-width="420">
      <v-card>
        <v-form ref="form-newuser" @submit.prevent="addNewUser" :disabled="savingNewUser">
          <v-card-title class="pt-2 pb-0">Novo usuário</v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              label="Nome completo"
              v-model="iptNome"
              :rules="[rules.obrigatorio]"
            ></v-text-field>
            <v-text-field
              label="Nome de exibição"
              v-model="iptApelido"
              hint="Nome que será exibido para os demais"
              persistent-hint
              :rules="[rules.obrigatorio]"
            ></v-text-field>
            <v-text-field
              label="Nome de usuário"
              v-model="iptUsername"
              hint="Palavra usada para realizar o login"
              persistent-hint
              :rules="[rules.obrigatorio]"
            ></v-text-field>
            <v-text-field
              label="CPF"
              v-model="iptCpf"
              :rules="[rules.obrigatorio, rules.numerico]"
            ></v-text-field>
            <v-text-field
              label="E-mail"
              v-model="iptEmail"
              hint="E-mail funcional para notificações e recuperação de senha"
              persistent-hint
              :rules="[rules.obrigatorio, rules.email]"
            ></v-text-field>
            <v-text-field
              label="Função"
              v-model="iptCargo"
              :rules="[rules.obrigatorio]"
            ></v-text-field>
            <v-select
              label="Sexo"
              v-model="iptSexo"
              :items="[{text: 'Feminino', value: 1}, {text: 'Masculino', value: 2}]"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary" text type="button" @click="dialogNewUser = false" :disabled="savingNewUser">CANCELAR</v-btn>
            <v-btn color="primary" text type="submit" :loading="savingNewUser">OK</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import UserWebClient from '@/http/UserWebClient';
import StringHelper from "@/helpers/StringHelper";
export default {
  name: 'Usuarios',
  components: {AsyncContainer},
  data: () => ({
    webClient: new UserWebClient(),
    loaded: false,
    items: [],
    headers: [
      {value: 'avatar', sortable: false},
      {text: 'Nome completo', value: 'nome'},
      {text: 'Função', value: 'cargo'},
    ],
    showInatives: false,
    search: '',
    dialogNewUser: false,
    iptNome: '',
    iptApelido: '',
    iptUsername: '',
    iptCpf: '',
    iptEmail: '',
    iptCargo: '',
    iptSexo: 1,
    savingNewUser: false,
    rules: {
      obrigatorio: (v) => (!!v && (typeof v === 'string' || typeof v === 'boolean')) || v === 0 || (Array.isArray(v) && v.length > 0) || 'Este campo é obrigatório. Faça o preenchimento.',
      numerico: v => (v && StringHelper.extractNumbers(v).length === v.trim().length) || 'Insira apenas números.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail inválido.'
      }
    },
    genericSrc: null, //src temporário do avatar generico
    podeGerenciarUsuarios: null, //Boolean
  }),
  methods: {
    async loadData() {
      try {
        this.items = await this.webClient.getUsuarios(true);
        const genericAvatar = await this.webClient.getGenericAvatar();
        this.genericSrc = URL.createObjectURL(genericAvatar);
        const perms = await this.webClient.getPermissoes(this.$store.getters['session/payload'].user);
        this.podeGerenciarUsuarios = perms.find(p => p.id === 4).status;
      } catch (e) {
        this.$router.push('/');
      } finally {
        this.loaded = true;
      }
    },
    async addNewUser() {
      if (!this.$refs['form-newuser'].validate()) return;
      this.savingNewUser = true;
      try {
        await this.webClient.insertUsuario(this.iptUsername, this.iptCpf, this.iptNome, this.iptApelido, this.iptEmail, this.iptCargo, this.iptSexo);
        this.$alert({text: `Usuário criado. A definição de senha foi enviada para ${this.iptEmail}.`, type: 'success'});
        this.dialogNewUser = false;
        this.limparCampos();
      } finally {
        this.savingNewUser = false;
      }
    },
    limparCampos() {
      this.iptNome = '';
      this.iptApelido = '';
      this.iptUsername = '';
      this.iptCpf = '';
      this.iptEmail = '';
      this.iptCargo = '';
      this.iptSexo = 1;
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    itemsActives() {
      return this.items.filter(i => i.ativo);
    },
  },
  watch: {
    dialogNewUser(v) {
      if (v === false) this.$refs['form-newuser'].resetValidation();
    }
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 0;
  }
</style>